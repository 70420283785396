@font-face {
  font-family: 'Proxima Nova';
  src: url('./proxima_nova_thin-webfont.woff2') format('woff2'),
    url('./proxima_nova_thin-webfont.woff') format('woff'),
    url('./proxima_nova_thin-webfont.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./proxima_nova_thin_it-webfont.woff2') format('woff2'),
    url('./proxima_nova_thin_it-webfont.woff') format('woff'),
    url('./proxima_nova_thin_it-webfont.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./proxima_nova_light-webfont.woff2') format('woff2'),
    url('./proxima_nova_light-webfont.woff') format('woff'),
    url('./proxima_nova_light-webfont.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./proxima_nova_light_it-webfont.woff2') format('woff2'),
    url('./proxima_nova_light_it-webfont.woff') format('woff'),
    url('./proxima_nova_light_it-webfont.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./proxima_nova_reg-webfont.woff2') format('woff2'),
    url('./proxima_nova_reg-webfont.woff') format('woff'),
    url('./proxima_nova_reg-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./proxima_nova_reg_it-webfont.woff2') format('woff2'),
    url('./proxima_nova_reg_it-webfont.woff') format('woff'),
    url('./proxima_nova_reg_it-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./proxima_nova_medium-webfont.woff2') format('woff2'),
    url('./proxima_nova_medium-webfont.woff') format('woff'),
    url('./proxima_nova_medium-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./proxima_nova_medium_it-webfont.woff2') format('woff2'),
    url('./proxima_nova_medium_it-webfont.woff') format('woff'),
    url('./proxima_nova_medium_it-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./proxima_nova_sbold-webfont.woff2') format('woff2'),
    url('./proxima_nova_sbold-webfont.woff') format('woff'),
    url('./proxima_nova_sbold-webfont.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./proxima_nova_sbold_it-webfont.woff2') format('woff2'),
    url('./proxima_nova_sbold_it-webfont.woff') format('woff'),
    url('./proxima_nova_sbold_it-webfont.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./proxima_nova_bold-webfont.woff2') format('woff2'),
    url('./proxima_nova_bold-webfont.woff') format('woff'),
    url('./proxima_nova_bold-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./proxima_nova_bold_it-webfont.woff2') format('woff2'),
    url('./proxima_nova_bold_it-webfont.woff') format('woff'),
    url('./proxima_nova_bold_it-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./proxima_nova_ebold-webfont.woff2') format('woff2'),
    url('./proxima_nova_ebold-webfont.woff') format('woff'),
    url('./proxima_nova_ebold-webfont.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./proxima_nova_ebold_it-webfont.woff2') format('woff2'),
    url('./proxima_nova_ebold_it-webfont.woff') format('woff'),
    url('./proxima_nova_ebold_it-webfont.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./proxima_nova_black-webfont.woff2') format('woff2'),
    url('./proxima_nova_black-webfont.woff') format('woff'),
    url('./proxima_nova_black-webfont.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./proxima_nova_black_it-webfont.woff2') format('woff2'),
    url('./proxima_nova_black_it-webfont.woff') format('woff'),
    url('./proxima_nova_black_it-webfont.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
